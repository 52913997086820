import { createContext, useMemo, FunctionComponent } from "react";
import * as React from "react";
import {
  SialogicContext,
  SialogicContextProviderProps,
} from "./app_context_provider.types";
import { fromPairs } from "lodash";

export const AppContext = createContext<SialogicContext>({
  currentAssetId: gon.asset_id,
  currentOrganizationId: gon.current_organization_id,
  vendorOrganizationId: gon.vendor_organization_id,
  businessHours: gon.business_hours,
  referrer: gon.referrer,
  theme: gon.theme,
  locale: gon.locale,
  user: gon.user,
  notificationSound: gon.notificationSound,
  subscribedModules: fromPairs(gon.subscribed_modules?.map((m) => [m, true])),
});

export const AppContextProvider: FunctionComponent<
  SialogicContextProviderProps
> = (props) => {
  const [context, setContext] = React.useState<SialogicContext>({
    currentAssetId: gon.asset_id,
    currentOrganizationId: gon.current_organization_id,
    vendorOrganizationId: gon.vendor_organization_id,
    businessHours: gon.business_hours,
    referrer: gon.referrer,
    theme: gon.theme,
    locale: gon.locale,
    user: gon.user,
    notificationSound: gon.notificationSound,
    subscribedModules: fromPairs(gon.subscribed_modules?.map((m) => [m, true])),
    map: gon.map,
  });

  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};
