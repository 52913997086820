import { CookieConsent, getCookieConsentValue } from "react-cookie-consent";

import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { AppRoot } from "../components/common/app_root";
import { terms_path } from "../routes";
import { Link } from "@mui/material";
export function showCookiesConsent() {
  try {
    const cookieName = "sialogic_cookie_consent";
    const consent = getCookieConsentValue(cookieName);

    if (isNil(consent)) {
      const element = $("#cookies-consent");
      const mountElement = element.get(0);
      if (!isNil(mountElement)) {
        const root = createRoot(mountElement);
        root.render(
          <AppRoot>
            <CookieConsent
              style={{ zIndex: 10000 }}
              buttonText={I18n.t(
                "frontend.cookie_banner.cookies_accept_button_text",
              )}
              acceptOnScroll={true}
              acceptOnScrollPercentage={50}
              cookieName={cookieName}
              location="bottom"
              hideOnAccept={true}
            >
              <div>
                {I18n.t("frontend.cookie_banner.cookies_usage_message")}
              </div>
              <div>
                {I18n.t(
                  "frontend.cookie_banner.find_more_information_in_privacy_policy",
                )}
                :&nbsp;
                <Link href={terms_path({ locale: I18n.locale })}>
                  {I18n.t("frontend.cookie_banner.privacy_policy")}
                </Link>
              </div>
            </CookieConsent>
          </AppRoot>,
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}
