import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/system";
import * as React from "react";
import { DefaultTheme } from "../styling/theme";

export interface AppRootProps extends React.PropsWithChildren {
  queryDevTools?: boolean;
}

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { error as errorToast } from "../../utils/toasts";
import { AppContextProvider } from "./app_context/app_context_provider";
import { HttpError } from "../../utils/jquery_helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const SialogicQueryClient = new QueryClient({
  defaultOptions: {},
  queryCache: new QueryCache({
    // global error handling ... display an error toast
    onError(error, query) {
      if (error instanceof HttpError) {
        if ((error as HttpError).request?.status == 401) {
        }
        // Handle 401 error here
      }
      void errorToast(I18n.t("frontend.error"), error.message);
    },
  }),
});

export const AppRoot: React.FunctionComponent<AppRootProps> = ({
  queryDevTools = false,
  ...props
}) => {
  return (
    <QueryClientProvider client={SialogicQueryClient}>
      <AppContextProvider>
        <StyledEngineProvider injectFirst>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={DefaultTheme}>{props.children}</ThemeProvider>
            {queryDevTools ? (
              <ReactQueryDevtools
                buttonPosition="bottom-left"
                client={SialogicQueryClient}
                initialIsOpen={false}
              />
            ) : null}
          </DndProvider>
        </StyledEngineProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};
