import { deDE as muiDeLocale, enUS as muiEnLocale } from "@mui/material/locale";
import {
  adaptV4Theme,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import {
  deDE as muiGridDeLocale,
  enUS as muiGridEnLocale,
} from "@mui/x-data-grid/locales";
// bind unload dialog

if (gon.locale == "de") {
  window.MUILocale = muiDeLocale;
  window.MUILocaleDataGrid = muiGridDeLocale as any;
} else {
  window.MUILocale = muiEnLocale;
  window.MUILocaleDataGrid = muiGridEnLocale as any;
}

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
      warning: React.CSSProperties["color"];
      info: React.CSSProperties["color"];
      success: React.CSSProperties["color"];
    };
  }
  interface DeprecatedThemeOptions {
    status: {
      danger: React.CSSProperties["color"];
      warning: React.CSSProperties["color"];
      info: React.CSSProperties["color"];
      success: React.CSSProperties["color"];
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

export const DefaultTheme = responsiveFontSizes(
  createTheme(
    adaptV4Theme(
      {
        palette: {
          action: {
            active: "#fffff",
          },
          neutral: {
            main: "#5c6ac4",
          },

          primary: {
            main: gon.theme?.primary_color ?? "#1ab394",
            contrastText: gon.theme?.button_text_color ?? "#fff",
          },
          warning: {
            main: gon.theme?.warning_color ?? "#f8ac59",
          },
          success: {
            main: gon.theme?.success_color ?? "#1c84c6",
          },
          error: { main: gon.theme?.danger_color ?? "#e53e3e" },
          info: { main: gon.theme?.info_color ?? "#23c6c8" },
        },

        typography: {
          body1: {
            fontSize: "13px",
          },
          body2: {
            fontSize: "12px",
          },
          h5: {
            fontSize: "16px",
          },
        },
        zIndex: {
          modal: 2100,

          snackbar: 2200,
          tooltip: 2300,
        },
      }, // locale is required in laguage packs, use it here to
    ),
    window.MUILocale, // localization for Grids
    window.MUILocaleDataGrid,
  ),
);
